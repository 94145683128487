import { inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CanActivateFn, CanMatchFn, Router } from '@angular/router';
import { OAuthService } from '@qaroni-app/application/auth/services/o-auth.service';
import { GuardOAuthSnackbars } from '../snackbars/guard-o-auth-snackbars.config';

export const authenticationGuard: CanActivateFn = () => {
  const redirectTo = '/';

  const router = inject(Router);
  const snackbar = inject(MatSnackBar);
  const authService = inject(OAuthService);

  if (authService.hasOAuth) {
    return true;
  }

  snackbar.open(
    GuardOAuthSnackbars.failOAuth.message,
    GuardOAuthSnackbars.failOAuth.closeBtn,
    GuardOAuthSnackbars.failOAuth.config
  );

  router.navigate([redirectTo]);
  return false;
};

export const oAuthCanMatch: CanMatchFn = () => {
  const redirectTo = '/';

  const authService = inject(OAuthService);
  const router = inject(Router);

  if (authService.hasOAuth) {
    return true;
  }

  router.navigate([redirectTo]);
  return false;
};
